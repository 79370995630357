import Image from 'next/image';
import { Brand } from 'types/Brand';
import { Card, CardImage } from './BrandCard.styled';
interface Props {
  data: Brand;
}
export default function CompanyCard({ data }: Props) {
  return (
    <Card href={`/products?brand_id=${data.id}&product_type=${data.brand_category[0]}&limit=10`}>
      <CardImage>
        <Image src={data.image} alt={data.name} layout="fill" objectFit="contain" priority={true} />
      </CardImage>
    </Card>
  );
}
