import Link from '@Components/common/Link';
import styled from 'styled-components';
import { rgba } from 'stylesheets/utils';

export const Card = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 127px;
  min-width: 127px;
  max-height: 127px;
  min-height: 127px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 3px 6px 0px ${({ theme }) => rgba(theme.colors.black, 0.16)};
`;

export const CardImage = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;
