import styled from 'styled-components';
import { mq, rgba } from 'stylesheets/utils';

export const Hero = styled.section`
  padding: 30px 40px 54px;
  background-image: url('/landing-hero.webp');
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  &::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${({ theme }) => rgba(theme.colors.heroOverlay, 0.8)};
  }
  ${mq(576)} {
    padding: 30px 10px 50px;
  }
`;

export const HeroTitle = styled.h4`
  font-size: ${({ theme }) => theme.fontsizes.h4};
  font-weight: ${({ theme }) => theme.fontWeight.font800};
  color: ${({ theme }) => theme.colors.white};
  z-index: 1;
  margin-bottom: 28px;
`;
export const HeroSectionContent = styled.div`
  display: flex;
  gap: 30px;
  z-index: 1;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 70%;
  ${mq(1200)} {
    max-width: 80%;
  }
  ${mq(992)} {
    max-width: unset;
  }
  ${mq(576)} {
    gap: 10px;
  }
`;
