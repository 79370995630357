import Link from '@Components/common/Link';
import styled from 'styled-components';
import { mq, rgba } from 'stylesheets/utils';
export const ServiceCardItem = styled(Link)`
  max-width: 180px;
  min-width: 180px;
  max-height: 170px;
  min-height: 170px;
  border-radius: 8px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 4px 7px 0px ${({ theme }) => rgba(theme.colors.black2, 0.16)};
  gap: 10px;
  ${mq(768)} {
    max-width: 110px;
    min-width: 110px;
    max-height: 110px;
    min-height: 110px;
  }
  ${mq(576)} {
    padding: 15px 10px 10px;
    max-width: 90px;
    min-width: 90px;
    max-height: 90px;
    min-height: 90px;
  }
`;

export const CardImage = styled.div`
  max-width: 60px;
  min-width: 60px;
  max-height: 60px;
  min-height: 60px;
  background-color: ${({ theme }) => theme.colors.brandColor};
  ${mq(768)} {
    max-width: 50px;
    min-width: 50px;
    max-height: 50px;
    min-height: 50px;
  }
  ${mq(576)} {
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-height: 30px;
  }
`;

export const ServiceTitle = styled.p`
  font-size: ${({ theme }) => theme.fontsizes.body16};
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  color: ${({ theme }) => theme.colors.black};
  text-align: center;
  max-height: calc(16px * 1.5 * 2);
  min-height: calc(16px * 1.5 * 2);
  text-overflow: ellipsis;
  overflow: hidden;
  ${mq(576)} {
    font-size: 1.3rem;
    max-height: calc(12px * 1.5 * 2);
    min-height: calc(12px * 1.5 * 2);
  }
`;
