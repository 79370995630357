import { SimpleCardItem } from 'types/common';
import { CardImage, ServiceCardItem, ServiceTitle } from './Service.styled';
interface Props {
  data: SimpleCardItem;
  icon?: string;
}

export default function ServiceCard({ data, icon }: Props) {
  return (
    <ServiceCardItem href={data.href} key={data.title}>
      <CardImage
        style={{
          maskImage: `url('/${icon}')`,
          WebkitMaskImage: `url('/${icon}')`,
          WebkitMaskRepeat: 'no-repeat',
          WebkitMaskSize: 'cover',
          WebkitMaskPosition: 'center',
        }}
      >
        {/* <Image priority={true} src={data.img} alt={data.title} width={60} height={60} /> */}
      </CardImage>
      <ServiceTitle>{data.title}</ServiceTitle>
    </ServiceCardItem>
  );
}
