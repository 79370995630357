import styled from 'styled-components';

export const CompanySection = styled.div`
  padding: 46px 0 58px 0;
`;

export const SectionTitle = styled.h5`
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  color: ${({ theme }) => theme.colors.black};
  font-size: 2.8rem;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
`;
export const SectionSubTitle = styled.h6`
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontsizes.h6};
  text-align: center;
  width: 100%;
  margin-bottom: 24px;
`;
export const CompanyList = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  justify-content: center;
`;
