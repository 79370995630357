import styled from 'styled-components';

export const Wrapper = styled.div`
  .best-seller-select {
    margin-bottom: 0;
    position: relative;
    z-index: 6;
    min-width: 157px;
    max-width: 157px;
    & > div:first-of-type > div > div:first-of-type {
      white-space: nowrap;
    }
  }
`;
