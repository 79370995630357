import Link from '@Components/common/Link';
import Select from 'react-select';
import styled from 'styled-components';
import { mq } from 'stylesheets/utils';
export const SelectItem = styled(Select)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  /* margin-bottom: 12px; */
  min-width: 156px;
  max-width: 156px;
  /* margin-right: 16px; */
  &:last-of-type {
    /* margin-right: 0; */
  }
  & > div {
    box-shadow: none;
    border: 1px solid #c4c8e3;
    border-radius: 4px;
  }
`;
export const ShowMoreArrow = styled.div`
  max-width: 24px;
  min-width: 24px;
  max-height: 24px;
  min-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(-1, 1) /*rtl: none */;
`;
export const Arrow = styled.div`
  max-width: 34px;
  min-width: 34px;
  max-height: 36px;
  min-height: 36px;
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%) /*rtl: translateY(-50%) */;
  right: 0px;
  background-color: ${({ theme }) => theme.colors.arrowBackground};
  display: flex;
  justify-content: center;
  align-items: center;
  transform-origin: center center;
  z-index: 2;
  /* transform: scale(-1, 1) ; */
  ${mq(1200)} {
    right: 5px;
  }
  &.landing-best-next {
    right: unset;
    left: 0px;
    transform: translateY(-50%) /*rtl: translateY(-50%)  rotate(180deg) */;
    ${mq(1200)} {
      left: 5px;
    }
  }
  &.landing-best-prev {
    transform: translateY(-50%) rotate(180deg) /*rtl: translateY(-50%)  rotate(0deg) */;
  }
`;
export const ShowMore = styled(Link)`
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  color: ${({ theme }) => theme.colors.cartCountColor};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  display: flex;
  align-items: center;
`;

export const BestSeller = styled.div`
  padding: 50px 0 42px 0;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  ${ShowMore} {
    margin-top: 30px;
    justify-content: center;
  }
`;
export const SectionTitle = styled.h5`
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  color: ${({ theme }) => theme.colors.black};
  font-size: 2.8rem;
  margin-bottom: 10px;
  text-align: center;
  width: 100%;
`;
export const SectionSubTitle = styled.h6`
  font-weight: ${({ theme }) => theme.fontWeight.font700};
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontsizes.h6};
  text-align: center;
  width: 100%;
  margin-bottom: 24px;
`;

export const FiltersList = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 35px;
  position: relative;
  z-index: 6;
  ${mq(576)} {
    gap: 14px;
    flex-wrap: wrap;
  }
`;
export const FiltersOuter = styled.div`
  display: flex;
`;
export const Filters = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;
export const FilterLabel = styled.p`
  font-weight: ${({ theme }) => theme.fontWeight.font400};
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  white-space: nowrap;
`;

export const FilterSliderWrapper = styled.div`
  padding: 0 60px;
  min-height: 360px;
  display: flex;
  justify-content: center;
  gap: 20px;
  ${mq(1200)} {
    justify-content: flex-start;
    padding: 0;
    overflow-x: auto;
    max-width: 90%;
    margin: 0 auto;
  }
  ${mq(576)} {
    padding: 0;
    margin: 0;
    max-width: none;
    gap: 10px;
  }
`;

export const FeaturesList = styled.div`
  padding-top: 30px;
  width: 100%;
`;
