import ServiceCard from '@Components/ServiceCard';
import useTranslation from 'next-translate/useTranslation';
import SteeringWheel from 'public/steering-wheel.svg';
import Wrench from 'public/Wrench.svg';
import { Hero, HeroSectionContent, HeroTitle } from './HeroSection.styled';

type Props = {};
export default function HeroSection({}: Props) {
  const { t } = useTranslation('Landing');

  return (
    <Hero>
      <HeroTitle>{t('hero-title')}</HeroTitle>
      <HeroSectionContent>
        <ServiceCard
          data={{
            img: SteeringWheel,
            title: t('buy-spareparts'),
            href: '/products?product_type=spare part',
          }}
          icon="steering-wheel.svg"
        />
        <ServiceCard
          data={{
            img: '/Wrench.svg',
            title: t('tyres'),
            href: '/products?product_type=tyre',
          }}
          icon="tyre.svg"
        />
        <ServiceCard
          data={{
            img: Wrench,
            title: t('engine_oil'),
            href: '/products/?product_type=oil',
          }}
          icon="oil.svg"
        />
        <ServiceCard
          data={{
            img: Wrench,
            title: t('battery'),
            href: '/products?product_type=battery',
          }}
          icon="battery.svg"
        />
        <ServiceCard
          data={{
            img: Wrench,
            title: t('spark_plug'),
            href: '/products?product_type=spark plug',
          }}
          icon="spark.svg"
        />
        <ServiceCard
          data={{
            img: Wrench,
            title: t('rims'),
            href: '/products?product_type=rim',
          }}
          icon="rim.svg"
        />
        <ServiceCard
          data={{
            img: Wrench,
            title: t('rim_cover'),
            href: '/products?product_type=rim cover',
          }}
          icon="rim_cover.svg"
        />
        <ServiceCard
          data={{
            img: Wrench,
            title: t('liquid'),
            href: '/products?product_type=liquid',
          }}
          icon="liquid.svg"
        />
        <ServiceCard
          data={{
            img: Wrench,
            title: t('accessories'),
            href: '/products?product_type=accessory',
          }}
          icon="accessories.svg"
        />
      </HeroSectionContent>
    </Hero>
  );
}
