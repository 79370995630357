import BrandCard from '@Components/BrandCard';
import BrandCardLoader from '@Components/BrandCard/BrandCard.loader';
import { useAuth } from '@Contexts/AuthContext/Auth.context';
import { _GetBrandsList } from '@Services/Brand';
import { K_BrandsList } from '@Utils/ReactQuery.keys';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useQuery } from 'react-query';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import { CompanyList, CompanySection, SectionSubTitle, SectionTitle } from './BestCompaniesSection.styled';

type Props = {};
export default function BestCompaniesSection({}: Props) {
  const { t } = useTranslation('common');
  const { isTokenValid } = useAuth();
  const { locale } = useRouter();
  const { data: brands, isLoading } = useQuery(
    K_BrandsList({
      limit: 6,
      locale,
    }),
    () =>
      _GetBrandsList({
        limit: 6,
      }),
    {
      enabled: isTokenValid,
    }
  );

  return (
    <CompanySection>
      <Container>
        <Row justifyContent="center">
          <Col col={12}>
            <SectionTitle>{t('best_company')}</SectionTitle>
            <SectionSubTitle>{t('parts')}</SectionSubTitle>
          </Col>
          <Col col={12} sm={9}>
            <CompanyList>
              {isLoading
                ? [...new Array(6)].map((_, i) => <BrandCardLoader key={i + '_Dummy_Brand_Loader'} />)
                : brands?.results.map((brand) => <BrandCard data={brand} key={brand.id + '_HOME_BRAND'} />)}
            </CompanyList>
          </Col>
        </Row>
      </Container>
    </CompanySection>
  );
}
