import React from 'react';
import ContentLoader from 'react-content-loader';

const BrandCardLoader = (props: any) => (
  <ContentLoader
    speed={2}
    width={127}
    height={127}
    viewBox="0 0 127 127"
    backgroundColor="#ededed"
    foregroundColor="#ffffff"
    {...props}
  >
    <circle cx="63" cy="63" r="63" />
  </ContentLoader>
);

export default BrandCardLoader;
