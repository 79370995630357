import { CommonSelect } from '@Components/common/CommonSelect';
import useTranslation from 'next-translate/useTranslation';
import React, { Fragment, useEffect, useState } from 'react';
import { PartCategory } from 'types/Categories';
import { SelectOption } from 'types/common';
import { Wrapper } from './CategoryFilters.styled';

type Props = {
  categories: PartCategory[];
  callback: (val?: SelectOption) => void;
  depth?: number;
};

export default React.memo(function CategoryFilters({ categories, callback, depth }: Props) {
  const { t } = useTranslation('Landing');
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  let theDepth = depth || 5;
  const theChildren =
    selectedCategory && categories.find((category) => category.id === selectedCategory.value)?.children;

  useEffect(() => {
    setSelectedCategory(null);
    return () => {
      setSelectedCategory(null);
    };
  }, [categories]);

  useEffect(() => {
    if (selectedCategory) {
      if (
        categories.some((cat) => cat.id === selectedCategory.value) &&
        !Boolean(categories.find((cat) => cat.id === selectedCategory.value)?.children.length)
      ) {
        callback(selectedCategory);
      } else {
        callback(undefined);
      }
    }
  }, [callback, categories, selectedCategory]);

  return (
    <Fragment>
      <Wrapper style={{ zIndex: theDepth || 0 }}>
        <CommonSelect
          className="best-seller-select"
          isClearable={false}
          isSearchable={false}
          placeholder={t('filter_label')}
          value={selectedCategory || null}
          options={categories.map((cat) => ({
            label: cat.name,
            value: cat.id,
          }))}
          onChange={(option: any) => {
            setSelectedCategory(option);
          }}
        />
      </Wrapper>
      {Boolean(theChildren?.length) && (
        <CategoryFilters categories={theChildren} callback={callback} depth={theDepth - 1} />
      )}
    </Fragment>
  );
});
