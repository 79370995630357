import CategoryFilters from '@Components/CategoryFilters';
import FeatureCard from '@Components/FeatureCard';
import ProductCard from '@Components/ProductCard';
import ProductCardLoader from '@Components/ProductCard/ProductCard.loader';
import RequestCustomItem from '@Components/RequestCustomItem';
import { _GetProducts } from '@Services/Products';
import { K_AllProducts } from '@Utils/ReactQuery.keys';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { useRouter } from 'next/router';
import FilterIcon from 'public/filter-icon.svg';
import Next from 'public/next.svg';
import DeliveryIcon from 'public/sell-icons/delivery.svg';
import DollarIcon from 'public/sell-icons/dollar.svg';
import GuarenteeIcon from 'public/sell-icons/guarentee.svg';
import WrenchIcon from 'public/sell-icons/wrench.svg';
import More from 'public/show-arrow.svg';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useMedia } from 'react-use';
import { RootState } from 'store';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { PartCategory } from 'types/Categories';
import { SelectOption } from 'types/common';
import {
  Arrow,
  BestSeller,
  FeaturesList,
  FilterLabel,
  Filters,
  FilterSliderWrapper,
  FiltersList,
  FiltersOuter,
  SectionSubTitle,
  SectionTitle,
  ShowMore,
  ShowMoreArrow,
} from './BestSellerSection.styled';
type Props = {};

interface OptionWithChildren {
  label: string;
  value: number;
  hasChildren?: PartCategory[];
}
export default function BestSellerSection({}: Props) {
  const { t } = useTranslation('Landing');
  const { t: tCommon } = useTranslation('common');
  const { data: categories } = useSelector((state: RootState) => state.categories);
  const { locale } = useRouter();
  const [offset, setOffset] = useState<number>();
  const [category_id, set_category_id] = useState();
  const isMobile = useMedia('(max-width: 576px)');
  const { data: products, isLoading } = useQuery(
    K_AllProducts({
      best_seller: true,
      limit: isMobile ? 2 : 4,
      locale,
      offset,
      category_id,
    }),
    () =>
      _GetProducts({
        best_seller: true,
        limit: isMobile ? 2 : 4,
        offset,
        category_id,
      })
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialProductsLength = useMemo(() => products?.results.length, []);

  const handleSelectCategory = useCallback((val?: SelectOption) => {
    set_category_id(val?.value);
  }, []);

  return Boolean(initialProductsLength) ? (
    <BestSeller>
      <Container>
        <Row>
          <Col col={12}>
            <SectionTitle>{t('best_seller_title')}</SectionTitle>
            <SectionSubTitle>{t('best_seller_subtitle')}</SectionSubTitle>
          </Col>
          <Col col={12}>
            <FiltersList>
              <Image src={FilterIcon} width={24} height={17.14} alt="filter-icon" />
              <FilterLabel>{t('filter_label')}</FilterLabel>
              <FiltersOuter>
                <Filters>
                  {categories && <CategoryFilters categories={categories} callback={handleSelectCategory} />}
                </Filters>
              </FiltersOuter>
            </FiltersList>
            <FilterSliderWrapper>
              {products ? (
                Boolean(products?.results.length) ? (
                  <Fragment>
                    {products.results.map((product) => (
                      <ProductCard data={product} key={product.id + '_PRODUCT_BEST_SELLER'} />
                    ))}
                    <Arrow
                      className="landing-best-prev"
                      onClick={() =>
                        !isLoading &&
                        setOffset((prev) => (prev ? (prev + 4 > Number(products?.count) ? prev : prev + 4) : 4))
                      }
                    >
                      <Image src={Next} width={9.71} height={17.76} alt="prev" priority={true} />
                    </Arrow>
                    <Arrow
                      className="landing-best-next"
                      onClick={() => !isLoading && setOffset((prev) => (prev ? (prev === 4 ? 0 : prev - 4) : prev))}
                    >
                      <Image src={Next} width={9.71} height={17.76} alt="next" priority={true} />
                    </Arrow>
                  </Fragment>
                ) : (
                  <RequestCustomItem />
                )
              ) : (
                [...new Array(4)].map((_, i) => <ProductCardLoader key={i + '_LANDING_BEST_SELLERS'} />)
              )}
            </FilterSliderWrapper>
          </Col>
          {Boolean(products?.results.length) && (
            <Col col={12}>
              <ShowMore href="/products?best_seller=true">
                {t('show_more')}
                <ShowMoreArrow>
                  <Image src={More} width={7.4} height={12} alt="show more arrow" />
                </ShowMoreArrow>
              </ShowMore>
            </Col>
          )}
          <Col col={12}>
            <FeaturesList>
              <Row justifyContent="center">
                <Col col={12} sm={6} md={4} lg={3}>
                  <FeatureCard
                    data={{
                      title: tCommon('sell-slogan-1'),
                      img: GuarenteeIcon,
                    }}
                  />
                </Col>
                <Col col={12} sm={6} md={4} lg={3}>
                  <FeatureCard
                    data={{
                      title: tCommon('sell-slogan-2'),
                      img: DeliveryIcon,
                    }}
                  />
                </Col>
                <Col col={12} sm={6} md={4} lg={3}>
                  <FeatureCard
                    data={{
                      title: tCommon('sell-slogan-3'),
                      img: DollarIcon,
                    }}
                  />
                </Col>
                <Col col={12} sm={6} md={4} lg={3}>
                  <FeatureCard
                    data={{
                      title: tCommon('sell-slogan-4'),
                      img: WrenchIcon,
                    }}
                  />
                </Col>
              </Row>
            </FeaturesList>
          </Col>
        </Row>
      </Container>
    </BestSeller>
  ) : (
    <Fragment></Fragment>
  );
}
